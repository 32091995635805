import { AppDispatch, RootState } from "@/src/app/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import React, { useEffect, useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import Button from "../../UI/Button/Button";
import BaseTable from "../../UI/Table/Table";
import { Column } from "react-table";
import moment from "moment";
import ActionsCell from "../../UI/ActionCell";
import { LoadingMaterialUI, SwitchButton } from "../../UI";
import TablePagination from "../../UI/Pagination/Pagination";
import { Pagination } from "../../../types/types";
import {
  PromoCodesQuery,
  useDeletePromoCodeMutation,
  usePromoCodesLazyQuery,
  useUpdatePromoCodeStatusMutation,
} from "../../../generated/graphql";
import { EPermission, isPermissionGranted } from "../../../utils/permissions";
import { LockOutlined } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import { displayAlertError } from "../../../app/globalSlice";
import Alert from "../../UI/Alerts/Alert";
import CheckBox from "../../UI/Checkbox/Checkbox";

const PromoCodes: React.FC = () => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { permissions, user: admin }: any = useSelector(
    (state: RootState) => state.auth
  );

  const pageSize = 25;

  const [pagination, setPagination] = React.useState<null | Pagination>(null);
  const [promoCodeModal, setPromoCodeModal] = React.useState<{
    open: boolean;
    promoCode: PromoCodesQuery["promoCodes"]["promoCodes"][0] | null;
    errorMessage: string | null;
    type: "delete" | "updateStatus";
  }>({
    open: false,
    promoCode: null,
    errorMessage: null,
    type: "delete",
  });

  const [getPromoCodes, { data: promoCodeData, loading, error }] =
    usePromoCodesLazyQuery({
      fetchPolicy: "network-only",
    });

  const [deletePromoCode, { loading: loadingDeletePromoCode }] =
    useDeletePromoCodeMutation({
      onCompleted: (data) => {
        if (data.deletePromoCode.success) {
          getPromoCodes({
            variables: {
              page: pagination?.page,
              pageSize: pagination?.pageSize,
            },
          });
          setPromoCodeModal((prevState) => ({
            ...prevState,
            open: false,
            errorMessage: null,
          }));
        } else {
          setPromoCodeModal((prevState) => ({
            ...prevState,
            open: true,
            errorMessage: data.deletePromoCode.message,
          }));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

  const [updatePromoCodeStatus, { loading: loadingUpdatePromoCodeStatus }] =
    useUpdatePromoCodeStatusMutation({
      onCompleted: (data) => {
        if (data.updatePromoCodeStatus.success) {
          getPromoCodes({
            variables: {
              page: pagination?.page,
              pageSize: pagination?.pageSize,
            },
          });
          setPromoCodeModal({
            open: false,
            promoCode: null,
            errorMessage: null,
            type: "delete",
          });
        } else {
          setPromoCodeModal((prevState) => ({
            ...prevState,
            errorMessage: data.updatePromoCodeStatus.message,
          }));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

  const redirectCreatePromoCode = () => {
    navigate(`/marketing/promo-codes/new`);
  };

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      setPromoCodeModal({
        open: true,
        promoCode: row.original,
        errorMessage: null,
        type: "delete",
      });
    }
    if (idx === 2) {
      setPromoCodeModal({
        open: true,
        promoCode: row.original,
        errorMessage: null,
        type: "updateStatus",
      });
    }
  };

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  useEffect(() => {
    getPromoCodes({
      variables: {
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      },
    });
  }, []);

  useEffect(() => {
    const page = 0;
    const pageCount = Math.ceil(
      (promoCodeData?.promoCodes.count || 1) / pageSize
    );
    setPagination({
      page,
      pageSize,
      pageCount,
    });
  }, [promoCodeData?.promoCodes.count]);

  const options = [
    { id: 1, text: "Delete" },
    { id: 2, text: "Update Status" },
  ];

  const COLUMNS: Column<PromoCodesQuery["promoCodes"]["promoCodes"][0]>[] = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => {
        return d.name;
      },
    },
    {
      Header: "Discount",
      id: "discount",
      accessor: (d) => {
        return (
          <div>
            {d.discount}
            {d.discountTypeId === 1 ? "%" : "$"}
          </div>
        );
      },
    },
    {
      Header: "Valid From",
      id: "validFrom",
      accessor: (d) => {
        return <div>{moment(d.validFrom).local().format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: "Expiry Date",
      id: "validTo",
      accessor: (d) => {
        return <div>{moment(d.validTo).local().format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: "Status",
      id: "isActive",
      accessor: (d) => {
        return d.isActive ? "Active" : "Inactive";
      },
    },
    {
      Header: "Updated By",
      id: "updatedBy",
      accessor: (d) => {
        return d.userUpdatedBy.firstName + " " + d.userUpdatedBy.lastName;
      },
    },
    {
      Header: "Regions",
      id: "regions",
      accessor: (d) => {
        return d.regions?.map((region) => region.name).join(", ");
      },
    },
    {
      Header: "Created At",
      id: "createdAt",
      accessor: (d) => {
        return <div>{d.createdAt}</div>;
      },
    },
    {
      Header: "Updated At",
      id: "updatedAt",
      accessor: (d) => {
        return <div>{d.updatedAt}</div>;
      },
    },
  ];

  const isPermGranted =
    admin && admin.permission
      ? isPermissionGranted(admin.permission, EPermission["PROMOCODE_DISCOUNT"])
      : false;

  if (isPermGranted) {
    COLUMNS.push({
      Header: " ",
      Cell: (data: any) => {
        return (
          <ActionsCell
            row={data.row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    });
  }

  const columns: any = useMemo(() => COLUMNS, []);
  const data = useMemo(
    () => promoCodeData?.promoCodes.promoCodes,
    [promoCodeData?.promoCodes.promoCodes]
  );

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Promo Codes</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant={isPermGranted ? "primary" : "disabled"}
            onClick={redirectCreatePromoCode}
            disabled={!isPermGranted}
          >
            <div className="flex justify-center gap-2">
              New Promo Code
              {!isPermGranted && <LockOutlined />}
            </div>
          </Button>
        </div>
      </div>
      {loading && <LoadingMaterialUI />}
      {promoCodeData && promoCodeData.promoCodes.promoCodes.length > 0 && (
        <BaseTable
          columns={columns}
          data={data}
        />
      )}
      {pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={pagination?.page || 0}
            pageCount={pagination?.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
      {!(promoCodeData && promoCodeData.promoCodes.promoCodes.length > 0) && (
        <div>No Data Found</div>
      )}
      <Modal
        open={promoCodeModal.open}
        onClose={() => {
          setPromoCodeModal({
            open: false,
            promoCode: null,
            errorMessage: null,
            type: "delete",
          });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {promoCodeModal.promoCode && (
            <h4>
              {promoCodeModal.type === "delete"
                ? "Delete "
                : "Update Status of "}
              Promo Code {promoCodeModal.promoCode.name}
            </h4>
          )}
          {promoCodeModal.errorMessage && (
            <Alert
              variant="error"
              size="small"
              persist={true}
              content={promoCodeModal?.errorMessage}
              mb="mb-4"
            />
          )}
          {promoCodeModal.type === "delete" && (
            <div> Are you sure you want to delete this promo code? </div>
          )}

          {promoCodeModal.type === "updateStatus" && (
            <div className="flex flex-col mb-4">
              <div>
                <div>
                  Selecting this checkbox will activate the promo code, making
                  it available for use.
                </div>
                <label className={`text-xs font-medium text-black`}>
                  Promo Code Status
                </label>
                <CheckBox
                  defaultChecked={promoCodeModal.promoCode?.isActive}
                  id="isActive"
                  label="Is Active"
                  inputChange={(val: boolean) =>
                    setPromoCodeModal((prevState) => ({
                      ...prevState,
                      promoCode: {
                        ...prevState.promoCode!,
                        isActive: val,
                      },
                    }))
                  }
                />
              </div>
            </div>
          )}

          <div className="flex justify-end gap-3 mt-5">
            <Button
              variant="secondary"
              onClick={() => {
                setPromoCodeModal({
                  open: false,
                  promoCode: null,
                  errorMessage: null,
                  type: "delete",
                });
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (promoCodeModal.type === "delete")
                  deletePromoCode({
                    variables: {
                      id: promoCodeModal.promoCode?.id || 0,
                    },
                  });
                if (promoCodeModal.type === "updateStatus")
                  updatePromoCodeStatus({
                    variables: {
                      id: promoCodeModal.promoCode?.id || 0,
                      isActive: !!promoCodeModal.promoCode?.isActive,
                    },
                  });
              }}
            >
              Confirm
            </Button>
          </div>
        </Box>
      </Modal>
    </main>
  );
};

export default PromoCodes;
