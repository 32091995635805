"use client";

import React, { FC, HTMLProps, ReactNode, useEffect } from "react";
import "./button.css";
import { cn } from "../../../lib/utils";
import { useGlobalContext } from "../../../context/GlobalContext";
import { CoolMode, particleList } from "./coolMode/coolMode";
import { useSound } from "use-sound";
import explosion from "../../../assets/sounds/explosion.mp3";
import { soundsList } from "../../../assets/sounds";

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  /**
   * The label of the button
   */
  label?: string;
  /**
   * The type of button
   */
  variant: "primary" | "secondary" | "negative" | "disabled";
  /**
   * Children of the button component. Allows jsx to be injected into the content of the button.
   */
  children: ReactNode;
  /**
   * Allows custom CSS to be used to style the button.
   */
  width?: "full" | "1/2" | "1/3" | "1/4";
  /**
   * Allows custom CSS to be used to style the button.
   */
  height?: string;
  /**
   * Custom classname for button styling
   */
  className?: string;
  /**
   * Type of button
   */
  type?: "button" | "submit" | "reset" | undefined;
  /**
   * Link to other page
   */
  href?: string;
}

const Button: FC<ButtonProps> = ({
  label,
  variant,
  children,
  width,
  height,
  className,
  type,
  href,
  ...props
}: ButtonProps) => {
  const combinedStyle =
    `storybook-button storybook-button--medium storybook-button--${variant} ` +
    "w-" +
    width +
    " h-" +
    height;

  const { coolMode } = useGlobalContext();
  useEffect(() => {
    console.log(coolMode);
  }, [coolMode]);
  const [play] = useSound(soundsList[coolMode.sound]?.src);

  const content = href ? (
    <a
      href={href}
      className={cn(combinedStyle, className)}
      onClick={(e) => {
        if (coolMode.isSound && coolMode.isCoolMode) {
          play();
        }
      }}
    >
      {children}
    </a>
  ) : (
    <button
      className={cn(combinedStyle, className)}
      type={type}
      {...props}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick.call(this, e);
          if (coolMode.isSound && coolMode.isCoolMode) {
            play();
          }
        } else {
          if (coolMode.isSound && coolMode.isCoolMode) {
            play();
          }
        }
      }}
    >
      {children}
    </button>
  );

  if (coolMode.isCoolMode && coolMode.isParticle) {
    return (
      <CoolMode
        options={{
          particleCount: 10,
          particle: particleList[coolMode.particle]?.src,
        }}
      >
        {content}
      </CoolMode>
    );
  } else {
    return content;
  }
};

export default Button;
