import React, { useEffect } from "react";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import Alert from "../../UI/Alerts/Alert";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import FormField from "../../UI/FormField/FormField";
import CheckBox from "../../UI/Checkbox/Checkbox";
import Body1 from "../../UI/Text/Body/Body1";
import dayjs from "dayjs";
import { z } from "zod";
import Caption1 from "../../UI/Text/Caption/Caption1";
import { DatePicker } from "../../UI/shadcn/Time/date-picker";
import Card from "../../UI/Card/Card";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import {
  EPermission,
  filterPermittedRegions,
  isPermissionGranted,
} from "../../../utils/permissions";
import {
  FormFieldSelectMulti,
  Item,
} from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import { useNavigate } from "react-router-dom";
import { useCreatePromoCodeMutation } from "../../../generated/graphql";
import { displayAlertSuccess } from "../../../app/globalSlice";
import { FormFieldSelectMultiSearch } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";

const PromoCodeSchema = z
  .object({
    name: z.string().trim().min(1, { message: "Promo Code name is required" }),
    discount: z
      .number()
      .int()
      .min(0, { message: "Discount must be a positive number" }),
    discountTypeId: z
      .number()
      .int()
      .min(1, { message: "Discount Type is required" }),
    validFrom: z
      .date()
      .refine(
        (data) => {
          return data !== undefined;
        },
        { message: "Valid From Date is required" }
      )
      .optional(),
    validTo: z
      .date()
      .refine(
        (data) => {
          return data !== undefined;
        },
        { message: "Valid To Date is required" }
      )
      .optional(),
    isActive: z.boolean(),
    regions: z.array(z.number()).optional(),
  })
  .refine(
    (data) => {
      if (data.validFrom && data.validTo) {
        return data.validFrom < data.validTo;
      }
    },
    { message: "Invalid dates", path: ["validFrom", "validTo"] }
  )
  .refine(
    (data) => {
      // If discount type is percentage, discount should be between 0 and 100
      if (data.discountTypeId === 1) {
        return data.discount >= 0 && data.discount <= 100;
      } else {
        return data.discount >= 0;
      }
    },
    { message: "Invalid discount value", path: ["discount"] }
  );

type PromoCodeType = z.infer<typeof PromoCodeSchema>;

const CreatePromoCode = () => {
  const pathsBreadcrumbs = [
    { name: "Promo Codes", url: "/marketing/promo-codes" },
    { name: "Create Promo Code", url: "/marketing/promo-codes/new" },
  ];
  const initialPromoCode: PromoCodeType = {
    name: "",
    discount: 0,
    discountTypeId: 1,
    validFrom: new Date(),
    validTo: new Date(),
    isActive: true,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { permissions, user: admin }: any = useSelector(
    (state: RootState) => state.auth
  );

  const { selectedRegions } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const [promoCode, setPromoCode] =
    React.useState<PromoCodeType>(initialPromoCode);
  const [promoCodeError, setPromoCodeError] = React.useState<{
    [key: string]: string;
  }>({
    name: "",
    discount: "",
    discountTypeId: "",
    validFrom: "",
    validTo: "",
  });
  const [promoCodeErrorMessages, setPromoCodeErrorMessages] = React.useState<
    string[]
  >([]);
  const [regions, setRegions] = React.useState<
    {
      label: number;
      value: string;
    }[]
  >([]);

  const [createPromoCode, { loading: loadingCreatePromoCode }] =
    useCreatePromoCodeMutation();

  useEffect(() => {
    const isPermGranted =
      admin && admin.permission
        ? isPermissionGranted(
            admin.permission,
            EPermission["PROMOCODE_DISCOUNT"]
          )
        : false;

    let permittedRegions: Item[] | undefined = undefined;
    if (isPermGranted) {
      const regions = (permittedRegions =
        admin && admin.permission
          ? filterPermittedRegions(
              admin.permission,
              EPermission["PROMOCODE_DISCOUNT"]
            )?.map((region) => {
              return {
                id: region.id,
                name: region.name,
              };
            })
          : undefined);
      permittedRegions =
        regions && regions.length > 0 ? regions : selectedRegions;
      setRegions(
        permittedRegions.map((region) => ({
          label: region.id,
          value: region.name,
        }))
      );
    } else {
      navigate("/marketing/promo-codes");
    }
  }, [admin]);

  const handleSubmit = () => {
    const result = PromoCodeSchema.safeParse(promoCode);
    let seasonArgs;
    if (result.success) {
      createPromoCode({
        variables: {
          promoCodeInput: {
            name: promoCode.name,
            discount: promoCode.discount,
            discountTypeId: promoCode.discountTypeId,
            validFrom: dayjs(promoCode.validFrom).format("YYYY-MM-DD"),
            validTo: dayjs(promoCode.validTo).format("YYYY-MM-DD"),
            isActive: promoCode.isActive,
            regions: promoCode.regions,
          },
        },
        onCompleted: (data) => {
          if (data.createPromoCode.success) {
            navigate("/marketing/promo-codes");
            dispatch(displayAlertSuccess("Promo Code created successfully"));
          } else {
            const message = [data.createPromoCode.message];
            setPromoCodeErrorMessages(message);
          }
        },
        onError: (error) => {
          setPromoCodeErrorMessages([
            "Error creating promo code. Check the form and try again. If the problem persists, contact support.",
          ]);
        },
      });
    } else {
      const newFormErrors = { ...promoCodeError };
      const newFormErrorKeys = Object.keys(newFormErrors);
      let messages: string[] = [];
      newFormErrorKeys.forEach((field) => {
        const fieldError = result.error.errors.find(
          (error) => error.path[0] === field
        );
        if (fieldError) {
          newFormErrors[field] = fieldError.message; // Set the error message
          messages.push(`Error ${field}: ${fieldError.message}`);
        } else {
          newFormErrors[field] = ""; // No matching error, set to an empty string
        }
      });
      setPromoCodeError(newFormErrors);
      setPromoCodeErrorMessages(messages);
      // dispatch(
      //   displayAlertError(
      //     `Please fix the form errors before ${
      //       season.id ? "updating" : "creating"
      //     } the season.`
      //   )
      // );
    }
  };

  return (
    <main className="h-[150vp] pb-80">
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/marketing/promo-codes"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>Create New Promo Code</Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
          >
            Create Promo Code
          </Button>
        </div>
      </div>
      {promoCodeErrorMessages.length > 0 && (
        <div className="flex flex-col gap-2">
          {promoCodeErrorMessages.map((errorMessage) => {
            return (
              <Alert
                variant="error"
                size="large"
                content={errorMessage}
                persist={true}
              />
            );
          })}
        </div>
      )}
      <Card className="mt-6">
        <Subtitle1>Promo Code Details</Subtitle1>
        <div className="flex flex-row items-center justify-center w-full gap-4 mt-6">
          <FormField
            initialValue={promoCode.name ? promoCode.name : ""}
            inputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPromoCode({ ...promoCode, name: e.target.value.toUpperCase() })
            }
            name="name"
            label="PromoCode Name"
          ></FormField>
          <FormFieldSelectMultiSearch
            name="regions"
            label="Regions"
            placeholder="Region"
            options={regions.map((region) => {
              return { value: region.label.toString(), label: region.value };
            })}
            defaultValue={
              promoCode.regions?.map((region) => region.toString()) || []
            }
            onValueChange={(values) => {
              setPromoCode((prevState) => ({
                ...prevState,
                regions: values.map((value) => +value),
              }));
            }}
          />
          <div className="w-1/3 m-auto">
            <CheckBox
              defaultChecked={promoCode.isActive}
              id="isActive"
              label="Is Active"
              inputChange={(val: boolean) =>
                setPromoCode({ ...promoCode, isActive: val })
              }
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-center w-full gap-4 mt-6">
          <FormFieldSelect
            inputChange={(val: number) =>
              setPromoCode({ ...promoCode, discountTypeId: +val })
            }
            name="discountTypeId"
            label="Discount Type"
            value={promoCode.discountTypeId.toString()}
            placeholder="Select Discount Type"
          >
            {[
              { id: 1, name: "Percentage" },
              { id: 2, name: "Fixed Amount" },
            ]}
          </FormFieldSelect>
          <FormField
            value={promoCode.discount ? promoCode.discount : 0}
            inputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPromoCode({ ...promoCode, discount: +e.target.value })
            }
            name="discount"
            label="Discount"
            type="number"
          ></FormField>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Promo Code Dates</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <Caption1 className="font-medium">Valid From</Caption1>
              <DatePicker
                className="w-full"
                date={
                  promoCode.validFrom
                    ? new Date(promoCode.validFrom)
                    : undefined
                }
                setDate={(date) =>
                  setPromoCode({
                    ...promoCode,
                    validFrom: date,
                  })
                }
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <Caption1 className="font-medium">Expiry Date</Caption1>
              <DatePicker
                className="w-full"
                date={
                  promoCode.validTo ? new Date(promoCode.validTo) : undefined
                }
                setDate={(date) =>
                  setPromoCode({
                    ...promoCode,
                    validTo: date,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Card>
      <div className="h-10 mt-5 text-right">
        <Button
          variant="primary"
          onClick={() => handleSubmit()}
        >
          Create Promo Code
        </Button>
      </div>
    </main>
  );
};

export default CreatePromoCode;
